/* eslint-disable no-shadow */
/* eslint-disable prefer-destructuring */
import { ref, watch } from '@vue/composition-api'
import axiosUtilities from '@/helpers/axiosUtilities'
import useAlertNotification from '@/services/notification/useAlert'
import useAuthenticate from '@/services/authentification/authService'

export default function useImageUpload() {
  const {
    handleAppLogin, process,
  } = useAuthenticate()
  const imageFile = ref('')
  const imageUrl = ref('')
  const fileName = ref('')
  const fil = ref('')
  const errors = ref([])
  const loader = ref(false)
  const { errorToast, successToast } = useAlertNotification()

  const uploadFile = async data => {
    loader.value = true
    await handleAppLogin()
    await axiosUtilities.post('/appose-cev', data)
      .then(response => {
        fileName.value = response.data.file.split('CEVs/')[1]
        loader.value = false
        // const pdfUrl = response.data.file;
        // const options = 'location=center,resizable=0,width=1024,height=630,popup'
        // window.open('https://ecev-appose.mameribj.org?n='+pdfUrl.split('CEVs/')[1], '_blank', options)
        successToast('Enregistrement', 'Fichier chargé avec succès')
      })
      .catch(error => {
        errors.value = ''
        errors.value = error.response.data.errors
        const uploadFileErrors = error.response.data.errors
        Object.keys(uploadFileErrors).forEach(key => {
          errorToast('Oups! Erreur', uploadFileErrors[key][0])
        })
      })
  }

  const deleteFile = async data => {
    await axiosUtilities.delete(`/remove-file/${data}`)
  }

  const handleImageSelected = event => {
    if (event.target.files.length === 0) {
      imageFile.value = ''
      imageUrl.value = ''
      return
    }

    imageFile.value = event.target.files[0]
  }

  watch(imageFile, imageFile => {
    if (!(imageFile instanceof File)) {
      return
    }

    const fileReader = new FileReader()

    fileReader.readAsDataURL(imageFile)

    fileReader.addEventListener('load', () => {
      imageUrl.value = fileReader.result
    })
  })

  return {
    imageFile,
    imageUrl,
    handleImageSelected,
    uploadFile,
    fileName,
    loader,
    deleteFile,
    fil,
  }
}
